import React, { useEffect, useState } from "react";
import { Col, Row, Container, Button } from "react-bootstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import { useQuery } from "react-apollo";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { hotjar } from "react-hotjar";

import LatestSlider from "../../../components/homePage/LatestSlider";
import LoadingView from "../../../components/app/LoadingView";
import { getMetaText, getCopyText } from "../../../AppText";
import { formatCurrency } from "../../../utils/currency";
import { RootState } from "../../../state/rootReducer";
import { CASKS, Cask } from "../../../graphql/Casks";
import GiftedComponent from "./GiftedComponent";
import styles from "./GiftingPage.module.scss";
import LoadingLogo from "../../../components/app/LoadingLogo";

const GiftingPage = () => {
  hotjar.initialize(2882523, 6);
  // @ts-ignore
  const { slug } = useParams();
  const location = useLocation();
  const shopId = useSelector((state: RootState) => state.shop.id);
  const shop = useSelector((state: RootState) => state.shop);

  const [caskData, setCaskData] = useState<any>([]);
  const [imgURL, setImgURL] = useState<string>(
    require("../../../assets/gifting-page/giftingProd2.jpg"),
  );

  const { data, loading: casksLoading } = useQuery(CASKS, {
    variables: {
      shop: shopId,
    },
    skip: !shopId,
  });

  useEffect(() => {
    let featuredCasks;
    let caskshares;

    //cask & bottles
    if (!data) {
      return;
    } else if (data && data.casks) {
      featuredCasks = data.casks.filter((cask: Cask) => cask.featured === true);
      caskshares = data.casks.filter(
        (cask: Cask) => cask.status === "published" && cask.featured === false,
      );
      const featuredCaskshares =
        featuredCasks.length <= 3
          ? featuredCasks.concat(caskshares)
          : featuredCasks;

      setCaskData(featuredCaskshares.slice(0, 4));
    }
    return () => {
      setCaskData([]);
    };
  }, [data, data?.casks]);

  useEffect(() => {
    // @ts-ignore
    if (location.state?.product) {
      // @ts-ignore
      setImgURL(location?.state?.product?.image);
    }
    return () => {
      setImgURL(require("../../../assets/gifting-page/giftingProd2.jpg"));
    };
  }, [location?.state]);

  const role = (() => {
    switch (slug) {
      case "voucher":
        return "voucher";
      case "membership":
        return "membership";
      case "caskshares":
        return "caskshares";
      default:
        return "voucher";
    }
  })();

  const pageName = (() => {
    switch (role) {
      case "voucher":
        return "GIFTING_PAGE_VOUCHER";
      case "membership":
        return "GIFTING_PAGE_MEMBERSHIP";
      case "caskshares":
        return "GIFTING_PAGE_CASKSHARES";
      default:
        return "GIFTING_PAGE_VOUCHER";
    }
  })();

  return (
    <>
      <Helmet>
        <title>{getMetaText(pageName, "title")}</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="keywords" content={getMetaText(pageName, "keywords")} />
        <meta
          name="description"
          content={getMetaText(pageName, "description")}
        />
        <meta name="author" content={getMetaText(pageName, "author")} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:title" content={getMetaText(pageName, "title")} />
        <meta
          property="og:description"
          content={getMetaText(pageName, "description")}
        />
        <meta
          property="og:image"
          content={
            window.location.origin +
            require("../../../assets/images/gifting-hero.png")
          }
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@thecaskshare" />
        <meta
          name="twitter:title"
          content={getMetaText("GIFTING_PAGE", "title")}
        />
        <meta
          name="twitter:description"
          content={getMetaText("GIFTING_PAGE", "description")}
        />
        <meta
          name="twitter:image"
          content={
            window.location.origin +
            require("../../../assets/images/gifting-hero.png")
          }
        />
      </Helmet>
      <Container fluid className={styles.giftingOuterWrap}>
        <Container className={styles.giftingWrap}>
          <Row className={styles.giftingRow}>
            <Col xs={12} lg={4} className={styles.imgWrap}>
              <div className={styles.img}>
                <img src={imgURL} alt="caskshare gifting product image" />
              </div>
            </Col>
            <Col xs={12} lg={4} className={styles.contentWrap}>
              <div className={styles.content}>
                <div className={styles.head}>
                  {role === "caskshares" ? (
                    <h3>Gift {role}</h3>
                  ) : (
                    <h3>Gift a {role}</h3>
                  )}
                  <p>{getCopyText(pageName, "paragraph")}</p>
                </div>
                <div className={styles.list}>
                  { shop.market !== "GB" && (
                    <div className={styles.inner}>
                      <span />
                      <p>{getCopyText(pageName, "bullet-1")}</p>
                    </div>
                  )}
                  <div className={styles.inner}>
                    <span />
                    <p>{getCopyText(pageName, "bullet-2")}</p>
                  </div>
                  { shop.market === "GB" && (
                    <div className={styles.inner}>
                      <span />
                      <p>{getCopyText(pageName, "bullet-3")}</p>
                    </div>
                  )}
                </div>
                <div className={styles.faq}>
                  <small>More questions?</small>
                  <Link to="/terms/faq">Check our FAQs</Link>
                </div>
              </div>
            </Col>
            <Col xs={12} lg={4} className={styles.formWrap}>
              <GiftedComponent />
            </Col>
          </Row>
          {/* -------------------More Gifting Ideas Slider-------------------- */}
          <Row className={styles.moreGiftingIdeas}></Row>
        </Container>
        {/* -------------------Footer Block-------------------- */}
        <Row className={styles.startCollection}>
          <Col xs={12} className={styles.inner}>
            <span />
            <h3>unique, timeless, accessible.</h3>
            <p>
              The uncompromised experience of your own maturing cask whisky, by
              the bottle - and world's finest distilleries at your fingertips.
            </p>
            <Button as={Link} to="/caskshares" className={styles.btnDark}>
              Start your collection
            </Button>
          </Col>
        </Row>
        {/* <Row className={styles.startCollection}>
          <Col xs={12} className={styles.inner}>
            <span />
            <h2>unique, timeless, accessible.</h2>
            <p>
              The uncompromised experience of your own maturing cask whisky, by
              the bottle - and world's finest distilleries at your fingertips.
            </p>
            <Button as={Link} to="/caskshares" className={styles.btnDark}>
              Browse caskshares
            </Button>
          </Col>
        </Row> */}
      </Container>
    </>
  );
};

export default GiftingPage;
