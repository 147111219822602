/* we're putting all the 'copy-write' text that appears in various places
    around the site in this file - along with functions to access it - with a view in the medium term to moving the text to some kind of database
    storage to make it easier to update */

const makeSubstitutions = (text: string, subs: any) => {
  Object.entries(subs).forEach(([findText, replaceText]) => {
    const findTextRgx = new RegExp(`\\[${findText}\\]`, "gi");
    // @ts-ignore
    text = text.replace(findTextRgx, replaceText);
  });
  return text;
};

// -------------------------------------------------------------------------------------------------
type PageName =
  | "ACCOUNT_OVERVIEW_PAGE"
  | "CLAIM_PAGE"
  | "BASKET_PAGE"
  | "CASK_PAGE"
  | "CASKS_PAGE"
  | "CHECKOUT_PAGE"
  | "CHECKOUT_SUCCESS_PAGE"
  | "GUEST_CHECKOUT_SUCCESS_PAGE"
  | "DISCOVER_PAGE"
  | "DISTILLERIES_PAGE"
  | "DISTILLERY_PAGE"
  | "HOME_PAGE"
  | "LOGIN_PAGE"
  | "REGISTER_PAGE"
  | "CONTACT_PAGE"
  | "PRODUCT_DETAILS_PAGE"
  | "SHOP_PAGE"
  | "FAQ_PAGE"
  | "TERMS_PAGE"
  | "COOKIES_PAGE"
  | "GIFTING_PAGE"
  | "GIFTING_PAGE_VOUCHER"
  | "GIFTING_PAGE_MEMBERSHIP"
  | "GIFTING_PAGE_CASKSHARES"
  | "CHECKOUT_TOTALS_COMPONENT"
  | "PRIVATE_SALE_PAGE"
  | "BALLOT_PAGE"
  | "CLAIM_PAGE"
  | "CLAIM_COMPONENT"
  | "ABOUT_PAGE"
  | "PRESS_PAGE"
  | "THE_COLLECTIVE"
  | "MY_COLLECTIVE"
  | "SIGNATURE_SERIES_PAGE"
  | "SELL_YOUR_CASK"
  | "TERMS_OF_USE"
  | "TERMS_AND_CONDITIONS"
  | "TERMS_AND_CONDITIONS_OF_BALLOT"
  | "QUIZ_PAGE"
  | "WELCOME_PAGE";

// -------------------------------------------------------------------------------------------------
type MetaName = "title" | "description" | "keywords" | "author";

const META_TEXT = {
  ABOUT_PAGE: {
    title: "About Page | Caskshare | Exclusive Single Cask Whisky",
    description: "Meet the spirited team behind Caskshare - small but mighty!",
    // keywords: "Cask, share, whisky, whiskey, about, team",
    keywords:
      "whisky bottlers, rare whisky for sale, single cask whisky, collectable whisky, old & rare whisky, rare scotch whiskey, collectors whisky for sale, rare single malt whisky, single malt sherry cask, rare single malt whisky, collectable whisky for sale, single barrel selection, selling rare whiskey, independent bottling, single cask, rare whisky bottles, cask strength, independent whisky bottlers, cask strength whisky, old and rare whisky, rare whisky",
    author: "Caskshare",
  },
  ACCOUNT_OVERVIEW_PAGE: {
    title: "[Customer-name] Caskshare | Exclusive Single Cask Whisky",
    description:
      "Caskshare secure account of cask whisky, your vault of cask whiskies",
    // keywords: "Cask, share, whisky, whiskey, secure, account",
    keywords:
      "whisky bottlers, rare whisky for sale, single cask whisky, collectable whisky, old & rare whisky, rare scotch whiskey, collectors whisky for sale, rare single malt whisky, single malt sherry cask, rare single malt whisky, collectable whisky for sale, single barrel selection, selling rare whiskey, independent bottling, single cask, rare whisky bottles, cask strength, independent whisky bottlers, cask strength whisky, old and rare whisky, rare whisky",
    author: "Caskshare",
  },
  CLAIM_PAGE: {
    title: "Claim Caskshare | Caskshare | Exclusive Single Cask Whisky",
    description:
      "Claim a caskshare that has been gifted or transferred with a code.",
    // keywords: "claim, code, caskshare, gift",
    keywords:
      "whisky bottlers, rare whisky for sale, single cask whisky, collectable whisky, old & rare whisky, rare scotch whiskey, collectors whisky for sale, rare single malt whisky, single malt sherry cask, rare single malt whisky, collectable whisky for sale, single barrel selection, selling rare whiskey, independent bottling, single cask, rare whisky bottles, cask strength, independent whisky bottlers, cask strength whisky, old and rare whisky, rare whisky",
    author: "Caskshare",
  },
  BASKET_PAGE: {
    title:
      "[Customer-name] - Cask Whisky Shopping Basket | Caskshare | Exclusive Single Cask Whisky",
    description: "Securely purchase single cask whisky with Caskshare",
    // keywords: "purchase, cask whisky, whiskey, shopping, basket",
    keywords:
      "whisky bottlers, rare whisky for sale, single cask whisky, collectable whisky, old & rare whisky, rare scotch whiskey, collectors whisky for sale, rare single malt whisky, single malt sherry cask, rare single malt whisky, collectable whisky for sale, single barrel selection, selling rare whiskey, independent bottling, single cask, rare whisky bottles, cask strength, independent whisky bottlers, cask strength whisky, old and rare whisky, rare whisky",
    author: "Caskshare",
  },
  PRIVATE_SALE_PAGE: {
    title: "[Private-Sale-name] | Caskshare | Exclusive Single Cask Whisky",
    description: "[Private-Sale-name] - [Private-Sale-description]",
    // keywords:
    //   "cask, whisky, whiskey, ballot, ballots, private, sale, casks, scotch",
    keywords:
      "whisky bottlers, rare whisky for sale, single cask whisky, collectable whisky, old & rare whisky, rare scotch whiskey, collectors whisky for sale, rare single malt whisky, single malt sherry cask, rare single malt whisky, collectable whisky for sale, single barrel selection, selling rare whiskey, independent bottling, single cask, rare whisky bottles, cask strength, independent whisky bottlers, cask strength whisky, old and rare whisky, rare whisky",
    author: "Caskshare",
  },
  BALLOT_PAGE: {
    title:
      "[Ballot-name] Distillery | Caskshare | Exclusive Single Cask Whisky",
    description: "[Ballot-name] - [Ballot-description]",
    // keywords:
    //   "cask, whisky, whiskey, ballot, ballots, private, sale, casks, scotch",
    keywords:
      "whisky bottlers, rare whisky for sale, single cask whisky, collectable whisky, old & rare whisky, rare scotch whiskey, collectors whisky for sale, rare single malt whisky, single malt sherry cask, rare single malt whisky, collectable whisky for sale, single barrel selection, selling rare whiskey, independent bottling, single cask, rare whisky bottles, cask strength, independent whisky bottlers, cask strength whisky, old and rare whisky, rare whisky",
    author: "Caskshare",
  },
  QUIZ_PAGE: {
    title:
      "Find your flavour profile | Caskshare | Exclusive Single Cask Whisky",
    description:
      "Answer 5 questions and discover what type of whisky suits you best. From flavour notes to cask type, you'll get your personalised flavour map and three new whisky recommendations that match your profile.",
    // keywords:
    //   "quiz, whisky quiz, single cask, whisky, whiskey, ballot, ballots, private, sale, casks, scotch",
    keywords:
      "whisky bottlers, rare whisky for sale, single cask whisky, collectable whisky, old & rare whisky, rare scotch whiskey, collectors whisky for sale, rare single malt whisky, single malt sherry cask, rare single malt whisky, collectable whisky for sale, single barrel selection, selling rare whiskey, independent bottling, single cask, rare whisky bottles, cask strength, independent whisky bottlers, cask strength whisky, old and rare whisky, rare whisky",
    author: "Caskshare",
  },
  CASK_PAGE: {
    title:
      "[Cask-name] - Whisky Cask | Caskshare | Exclusive Single Cask Whisky",
    description: "[Cask-name] - [Cask-description]",
    // keywords: "cask, whisky, whiskey",
    keywords:
      "whisky bottlers, rare whisky for sale, single cask whisky, collectable whisky, old & rare whisky, rare scotch whiskey, collectors whisky for sale, rare single malt whisky, single malt sherry cask, rare single malt whisky, collectable whisky for sale, single barrel selection, selling rare whiskey, independent bottling, single cask, rare whisky bottles, cask strength, independent whisky bottlers, cask strength whisky, old and rare whisky, rare whisky",
    author: "Caskshare",
  },
  CASKS_PAGE: {
    title:
      "Whisky Casks to choose from | Caskshare | Exclusive Single Cask Whisky",
    description:
      "Choose your next whisky from our range of maturing single casks. Buy for delivery now, or reserve, mature and enjoy.",
    // keywords:
    //   "distillery, distilleries, casks, whisky, whiskey, caskshare, caskshares, casks, share, invest",
    keywords:
      "whisky bottlers, rare whisky for sale, single cask whisky, collectable whisky, old & rare whisky, rare scotch whiskey, collectors whisky for sale, rare single malt whisky, single malt sherry cask, rare single malt whisky, collectable whisky for sale, single barrel selection, selling rare whiskey, independent bottling, single cask, rare whisky bottles, cask strength, independent whisky bottlers, cask strength whisky, old and rare whisky, rare whisky",
    author: "Caskshare",
  },
  CHECKOUT_PAGE: {
    title: "Checkout Securely | Caskshare | Exclusive Single Cask Whisky",
    description:
      "Enter details to checkout and purchase your shares of cask whisky",
    // keywords: "checkout, caskshare, whisky, bottles, shares",
    keywords:
      "whisky bottlers, rare whisky for sale, single cask whisky, collectable whisky, old & rare whisky, rare scotch whiskey, collectors whisky for sale, rare single malt whisky, single malt sherry cask, rare single malt whisky, collectable whisky for sale, single barrel selection, selling rare whiskey, independent bottling, single cask, rare whisky bottles, cask strength, independent whisky bottlers, cask strength whisky, old and rare whisky, rare whisky",
    author: "Caskshare",
  },
  CHECKOUT_SUCCESS_PAGE: {
    title: "Successful Purchase | Caskshare | Exclusive Single Cask Whisky",
    description:
      "Congratulations you have successfully purchased your latest addition to your cask whisky",
    // keywords: "successful, purchase, cask, whisky, whiskey",
    keywords:
      "whisky bottlers, rare whisky for sale, single cask whisky, collectable whisky, old & rare whisky, rare scotch whiskey, collectors whisky for sale, rare single malt whisky, single malt sherry cask, rare single malt whisky, collectable whisky for sale, single barrel selection, selling rare whiskey, independent bottling, single cask, rare whisky bottles, cask strength, independent whisky bottlers, cask strength whisky, old and rare whisky, rare whisky",
    author: "Caskshare",
  },
  GUEST_CHECKOUT_SUCCESS_PAGE: {
    title: "Successful Purchase | Caskshare | Exclusive Single Cask Whisky",
    description:
      "Congratulations you have successfully purchased your latest addition to your cask whisky",
    // keywords: "successful, purchase, cask, whisky, whiskey",
    keywords:
      "whisky bottlers, rare whisky for sale, single cask whisky, collectable whisky, old & rare whisky, rare scotch whiskey, collectors whisky for sale, rare single malt whisky, single malt sherry cask, rare single malt whisky, collectable whisky for sale, single barrel selection, selling rare whiskey, independent bottling, single cask, rare whisky bottles, cask strength, independent whisky bottlers, cask strength whisky, old and rare whisky, rare whisky",
    author: "Caskshare",
  },
  DISCOVER_PAGE: {
    title: " Caskshare | Exclusive Single Cask Whisky",
    description: "Browse or search for bottles of cask whisky to buy",
    // keywords: "browse, find, search , whiskey, whisky",
    keywords:
      "whisky bottlers, rare whisky for sale, single cask whisky, collectable whisky, old & rare whisky, rare scotch whiskey, collectors whisky for sale, rare single malt whisky, single malt sherry cask, rare single malt whisky, collectable whisky for sale, single barrel selection, selling rare whiskey, independent bottling, single cask, rare whisky bottles, cask strength, independent whisky bottlers, cask strength whisky, old and rare whisky, rare whisky",
    author: "Caskshare",
  },
  DISTILLERIES_PAGE: {
    title:
      "Cask Whisky Distilleries | Caskshare | Exclusive Single Cask Whisky",
    description:
      "Each distillery has its own story, its own heritage and its own tastes.  Visit now to find out more.",
    // keywords: "distilleries, whisky, distilleries",
    keywords:
      "whisky bottlers, rare whisky for sale, single cask whisky, collectable whisky, old & rare whisky, rare scotch whiskey, collectors whisky for sale, rare single malt whisky, single malt sherry cask, rare single malt whisky, collectable whisky for sale, single barrel selection, selling rare whiskey, independent bottling, single cask, rare whisky bottles, cask strength, independent whisky bottlers, cask strength whisky, old and rare whisky, rare whisky",
    author: "Caskshare",
  },
  DISTILLERY_PAGE: {
    title: "[Distillery-name] | Caskshare | Exclusive Single Cask Whisky",
    description:
      "Welcome to the [Distillery-name] range of cask whisky to buy.  [Distillery-description]",
    // keywords: "whisky, whiskey distillery, collection, cask, casks",
    keywords:
      "whisky bottlers, rare whisky for sale, single cask whisky, collectable whisky, old & rare whisky, rare scotch whiskey, collectors whisky for sale, rare single malt whisky, single malt sherry cask, rare single malt whisky, collectable whisky for sale, single barrel selection, selling rare whiskey, independent bottling, single cask, rare whisky bottles, cask strength, independent whisky bottlers, cask strength whisky, old and rare whisky, rare whisky",
    author: "Caskshare",
  },
  HOME_PAGE: {
    title: "Caskshare | Exclusive Single Cask Whisky",
    description:
      "The uncompromised experience of your own maturing cask whisky, by the bottle - and world's finest distilleries at your fingertips.",
    // keywords: "buy, single, cask, whisky, whiskey, reserve, share",
    keywords:
      "whisky bottlers, rare whisky for sale, single cask whisky, collectable whisky, old & rare whisky, rare scotch whiskey, collectors whisky for sale, rare single malt whisky, single malt sherry cask, rare single malt whisky, collectable whisky for sale, single barrel selection, selling rare whiskey, independent bottling, single cask, rare whisky bottles, cask strength, independent whisky bottlers, cask strength whisky, old and rare whisky, rare whisky",
    author: "Caskshare",
  },
  LOGIN_PAGE: {
    title: "Caskshare Login | Caskshare | Exclusive Single Cask Whisky",
    description:
      "Login to see your cask whisky and to purchase more cask whisky shares.",
    // keywords: "Login, register, account, whisky, whiskey",
    keywords:
      "whisky bottlers, rare whisky for sale, single cask whisky, collectable whisky, old & rare whisky, rare scotch whiskey, collectors whisky for sale, rare single malt whisky, single malt sherry cask, rare single malt whisky, collectable whisky for sale, single barrel selection, selling rare whiskey, independent bottling, single cask, rare whisky bottles, cask strength, independent whisky bottlers, cask strength whisky, old and rare whisky, rare whisky",
    author: "Caskshare",
  },
  PRESS_PAGE: {
    title: "In The Press | Caskshare | Exclusive Single Cask Whisky",
    description:
      "Visit to see the latest single cask whisky news on Caskshare.",
    // keywords: "Cask, share, whisky, whiskey, article, press",
    keywords:
      "whisky bottlers, rare whisky for sale, single cask whisky, collectable whisky, old & rare whisky, rare scotch whiskey, collectors whisky for sale, rare single malt whisky, single malt sherry cask, rare single malt whisky, collectable whisky for sale, single barrel selection, selling rare whiskey, independent bottling, single cask, rare whisky bottles, cask strength, independent whisky bottlers, cask strength whisky, old and rare whisky, rare whisky",
    author: "Caskshare",
  },
  REGISTER_PAGE: {
    title: "Join The Community | Caskshare | Exclusive Single Cask Whisky",
    description:
      "Register with Caskshare to buy shares in cask whisky.  Visit now to find out more.",
    // keywords: "register, share, cask, whisky, whiskey",
    keywords:
      "whisky bottlers, rare whisky for sale, single cask whisky, collectable whisky, old & rare whisky, rare scotch whiskey, collectors whisky for sale, rare single malt whisky, single malt sherry cask, rare single malt whisky, collectable whisky for sale, single barrel selection, selling rare whiskey, independent bottling, single cask, rare whisky bottles, cask strength, independent whisky bottlers, cask strength whisky, old and rare whisky, rare whisky",
    author: "Caskshare",
  },
  CONTACT_PAGE: {
    title: "Get In Touch | Caskshare",
    description:
      "Get in touch with Caskshare to find out more about buying cask whisky",
    // keywords: "contact, caskshare, whisky, casks",
    keywords:
      "whisky bottlers, rare whisky for sale, single cask whisky, collectable whisky, old & rare whisky, rare scotch whiskey, collectors whisky for sale, rare single malt whisky, single malt sherry cask, rare single malt whisky, collectable whisky for sale, single barrel selection, selling rare whiskey, independent bottling, single cask, rare whisky bottles, cask strength, independent whisky bottlers, cask strength whisky, old and rare whisky, rare whisky",
    author: "Caskshare",
  },
  PRODUCT_DETAILS_PAGE: {
    title:
      "Whisky Bottles to choose from | Caskshare | Exclusive Single Cask Whisky",
    description:
      "Choose your next whisky from our range of maturing single casks. Buy for delivery now, or reserve, mature and enjoy.",
    // keywords:
    //   "distillery, distilleries, casks, whisky, whiskey, caskshare, caskshares, casks, share, invest",
    keywords:
      "whisky bottlers, rare whisky for sale, single cask whisky, collectable whisky, old & rare whisky, rare scotch whiskey, collectors whisky for sale, rare single malt whisky, single malt sherry cask, rare single malt whisky, collectable whisky for sale, single barrel selection, selling rare whiskey, independent bottling, single cask, rare whisky bottles, cask strength, independent whisky bottlers, cask strength whisky, old and rare whisky, rare whisky",
    author: "Caskshare",
  },
  SHOP_PAGE: {
    title: "Whisky Bottle Shop | Caskshare | Exclusive Single Cask Whisky",
    description:
      "We hope that you find some whisky that interests you. Simply find whisky from our collection, select the quantity and purchase securely.",
    // keywords:
    //   "distillery, distillieries, bottles, whisky, whiskey, bourbon, worldwide, scotch",
    keywords:
      "whisky bottlers, rare whisky for sale, single cask whisky, collectable whisky, old & rare whisky, rare scotch whiskey, collectors whisky for sale, rare single malt whisky, single malt sherry cask, rare single malt whisky, collectable whisky for sale, single barrel selection, selling rare whiskey, independent bottling, single cask, rare whisky bottles, cask strength, independent whisky bottlers, cask strength whisky, old and rare whisky, rare whisky",
    author: "Caskshare",
  },
  SIGNATURE_SERIES_PAGE: {
    title: "[Seller-title] Signature Series",
    description:
      "We hope that you love whisky as much as we do. Here, you can find information on how to list your own cask with Caskshare!",
    // keywords: "whisky, whiskey, distillery, sell, cask, casks",
    keywords:
      "whisky bottlers, rare whisky for sale, single cask whisky, collectable whisky, old & rare whisky, rare scotch whiskey, collectors whisky for sale, rare single malt whisky, single malt sherry cask, rare single malt whisky, collectable whisky for sale, single barrel selection, selling rare whiskey, independent bottling, single cask, rare whisky bottles, cask strength, independent whisky bottlers, cask strength whisky, old and rare whisky, rare whisky",
    author: "Caskshare",
  },
  SELL_YOUR_CASK: {
    title: "Sell Your Cask | Caskshare | Exclusive Single Cask Whisky",
    description: "[Seller-description]",
    // keywords: "whisky, whiskey distillery, collection, cask, casks",
    keywords:
      "whisky bottlers, rare whisky for sale, single cask whisky, collectable whisky, old & rare whisky, rare scotch whiskey, collectors whisky for sale, rare single malt whisky, single malt sherry cask, rare single malt whisky, collectable whisky for sale, single barrel selection, selling rare whiskey, independent bottling, single cask, rare whisky bottles, cask strength, independent whisky bottlers, cask strength whisky, old and rare whisky, rare whisky",
    author: "Caskshare",
  },
  TERMS_PAGE: {
    title: "Terms and Conditions | Caskshare | Exclusive Single Cask Whisky",
    description: "Caskshare terms and conditions.",
    // keywords: "Caskshare, terms, conditions, t&cs, t&c",
    keywords:
      "whisky bottlers, rare whisky for sale, single cask whisky, collectable whisky, old & rare whisky, rare scotch whiskey, collectors whisky for sale, rare single malt whisky, single malt sherry cask, rare single malt whisky, collectable whisky for sale, single barrel selection, selling rare whiskey, independent bottling, single cask, rare whisky bottles, cask strength, independent whisky bottlers, cask strength whisky, old and rare whisky, rare whisky",
    author: "Caskshare",
  },
  FAQ_PAGE: {
    title: "FAQ | Caskshare | Exclusive Single Cask Whisky",
    description:
      "Frequently asked questions - visit now to find out more about how Caskshare works.",
    // keywords: "faq, caskshare",
    keywords:
      "whisky bottlers, rare whisky for sale, single cask whisky, collectable whisky, old & rare whisky, rare scotch whiskey, collectors whisky for sale, rare single malt whisky, single malt sherry cask, rare single malt whisky, collectable whisky for sale, single barrel selection, selling rare whiskey, independent bottling, single cask, rare whisky bottles, cask strength, independent whisky bottlers, cask strength whisky, old and rare whisky, rare whisky",
    author: "Caskshare",
  },
  COOKIES_PAGE: {
    title: "Privacy Policy | Caskshare | Exclusive Single Cask Whisky",
    description: "The Caskshare privacy policy.",
    // keywords: "caskshare, privacy, policy.",
    keywords:
      "whisky bottlers, rare whisky for sale, single cask whisky, collectable whisky, old & rare whisky, rare scotch whiskey, collectors whisky for sale, rare single malt whisky, single malt sherry cask, rare single malt whisky, collectable whisky for sale, single barrel selection, selling rare whiskey, independent bottling, single cask, rare whisky bottles, cask strength, independent whisky bottlers, cask strength whisky, old and rare whisky, rare whisky",
    author: "Caskshare",
  },
  TERMS_OF_USE: {
    title: "Terms of Use | Caskshare | Exclusive Single Cask Whisky",
    description: "Terms of use for the Caskshare website.",
    // keywords: "Terms, use, Caskshare, website.",
    keywords:
      "whisky bottlers, rare whisky for sale, single cask whisky, collectable whisky, old & rare whisky, rare scotch whiskey, collectors whisky for sale, rare single malt whisky, single malt sherry cask, rare single malt whisky, collectable whisky for sale, single barrel selection, selling rare whiskey, independent bottling, single cask, rare whisky bottles, cask strength, independent whisky bottlers, cask strength whisky, old and rare whisky, rare whisky",
    author: "Caskshare",
  },
  TERMS_AND_CONDITIONS: {
    title: "Customer Terms and Conditions",
    description: "Terms of use for the Caskshare website.",
    // keywords: "Terms, use, Caskshare, website.",
    keywords:
      "whisky bottlers, rare whisky for sale, single cask whisky, collectable whisky, old & rare whisky, rare scotch whiskey, collectors whisky for sale, rare single malt whisky, single malt sherry cask, rare single malt whisky, collectable whisky for sale, single barrel selection, selling rare whiskey, independent bottling, single cask, rare whisky bottles, cask strength, independent whisky bottlers, cask strength whisky, old and rare whisky, rare whisky",
    author: "Caskshare",
  },
  TERMS_AND_CONDITIONS_OF_BALLOT: {
    title: "Ballot Terms and Conditions",
    description: "Terms of use for the Caskshare website.",
    // keywords: "Terms, use, Caskshare, website.",
    keywords:
      "whisky bottlers, rare whisky for sale, single cask whisky, collectable whisky, old & rare whisky, rare scotch whiskey, collectors whisky for sale, rare single malt whisky, single malt sherry cask, rare single malt whisky, collectable whisky for sale, single barrel selection, selling rare whiskey, independent bottling, single cask, rare whisky bottles, cask strength, independent whisky bottlers, cask strength whisky, old and rare whisky, rare whisky",
    author: "Caskshare",
  },
  GIFTING_PAGE: {
    title:
      "Gift whisky and share the love | Caskshare | Exclusive Single Cask Whisky",
    description: `Gifting whisky with Caskshare is easy. It's a great way to start someone off on their single cask whisky journey.`,
    // keywords:
    //   "whisky, whiskey, distillery, collection, cask, casks, gifts, gift, gifting, collective, barrelbank, shares",
    keywords:
      "whisky bottlers, rare whisky for sale, single cask whisky, collectable whisky, old & rare whisky, rare scotch whiskey, collectors whisky for sale, rare single malt whisky, single malt sherry cask, rare single malt whisky, collectable whisky for sale, single barrel selection, selling rare whiskey, independent bottling, single cask, rare whisky bottles, cask strength, independent whisky bottlers, cask strength whisky, old and rare whisky, rare whisky",
    author: "Caskshare",
  },
  GIFTING_PAGE_VOUCHER: {
    title: "Gift a Voucher| Caskshare | Exclusive Single Cask Whisky",
    description: `Gifting with Caskshare is easy. It's a great way to start someone off on their journey of investing in and collecting single cask whisky.`,
    // keywords:
    //   "whisky, whiskey, distillery, collection, collective, cask, casks",
    keywords:
      "whisky bottlers, rare whisky for sale, single cask whisky, collectable whisky, old & rare whisky, rare scotch whiskey, collectors whisky for sale, rare single malt whisky, single malt sherry cask, rare single malt whisky, collectable whisky for sale, single barrel selection, selling rare whiskey, independent bottling, single cask, rare whisky bottles, cask strength, independent whisky bottlers, cask strength whisky, old and rare whisky, rare whisky",
    author: "Caskshare",
  },
  GIFTING_PAGE_MEMBERSHIP: {
    title: "Whisky Gifts | Caskshare | Exclusive Single Cask Whisky",
    description: `Gifting whisky in Caskshare is easy. It's a great way to start someone off on their journey of investing in and collecting single cask whisky.`,
    // keywords:
    //   "whisky, whiskey, distillery, collection, collective, cask, casks",
    keywords:
      "whisky bottlers, rare whisky for sale, single cask whisky, collectable whisky, old & rare whisky, rare scotch whiskey, collectors whisky for sale, rare single malt whisky, single malt sherry cask, rare single malt whisky, collectable whisky for sale, single barrel selection, selling rare whiskey, independent bottling, single cask, rare whisky bottles, cask strength, independent whisky bottlers, cask strength whisky, old and rare whisky, rare whisky",
    author: "Caskshare",
  },
  GIFTING_PAGE_CASKSHARES: {
    title: "Gift a Caskshares",
    description: `Gifting with Caskshare is easy. It's a great way to start someone off on their journey of investing in and collecting single cask whisky.`,
    // keywords:
    //   "whisky, whiskey, distillery, collection, collective, cask, casks",
    keywords:
      "whisky bottlers, rare whisky for sale, single cask whisky, collectable whisky, old & rare whisky, rare scotch whiskey, collectors whisky for sale, rare single malt whisky, single malt sherry cask, rare single malt whisky, collectable whisky for sale, single barrel selection, selling rare whiskey, independent bottling, single cask, rare whisky bottles, cask strength, independent whisky bottlers, cask strength whisky, old and rare whisky, rare whisky",
    author: "Caskshare",
  },
  THE_COLLECTIVE: {
    title: "The Collective | Caskshare | Exclusive Single Cask Whisky",
    description: `Join the Caskshare Collective to receive exclusive access to unique whiskies and monitor their progress as they mature, direct to your door.`,
    // keywords:
    //   "whisky, whiskey, distillery, collection, collective, cask, casks, membership, event, maker, podcasts, benefits, join",
    keywords:
      "whisky bottlers, rare whisky for sale, single cask whisky, collectable whisky, old & rare whisky, rare scotch whiskey, collectors whisky for sale, rare single malt whisky, single malt sherry cask, rare single malt whisky, collectable whisky for sale, single barrel selection, selling rare whiskey, independent bottling, single cask, rare whisky bottles, cask strength, independent whisky bottlers, cask strength whisky, old and rare whisky, rare whisky",
    author: "Caskshare",
  },
  MY_COLLECTIVE: {
    title: "My Collective | Caskshare | Exclusive Single Cask Whisky",
    description: `Welcome to Your Collective page. Here you’ll have access to all of the exclusive events, tasting packs and priority access to newly released products.`,
    // keywords:
    //   "whisky, whiskey, distillery, collection, collective, cask, casks, membership, event, maker, podcasts, benefits, join",
    keywords:
      "whisky bottlers, rare whisky for sale, single cask whisky, collectable whisky, old & rare whisky, rare scotch whiskey, collectors whisky for sale, rare single malt whisky, single malt sherry cask, rare single malt whisky, collectable whisky for sale, single barrel selection, selling rare whiskey, independent bottling, single cask, rare whisky bottles, cask strength, independent whisky bottlers, cask strength whisky, old and rare whisky, rare whisky",
    author: "Caskshare",
  },
  WELCOME_PAGE: {
    title: "Welcome | Caskshare",
    description:
      "Welcome to Caskshare! The home of unique, one-of-a-kind single cask whisky.",
    // keywords: "contact, caskshare, whisky, casks",
    keywords:
      "whisky bottlers, rare whisky for sale, single cask whisky, collectable whisky, old & rare whisky, rare scotch whiskey, collectors whisky for sale, rare single malt whisky, single malt sherry cask, rare single malt whisky, collectable whisky for sale, single barrel selection, selling rare whiskey, independent bottling, single cask, rare whisky bottles, cask strength, independent whisky bottlers, cask strength whisky, old and rare whisky, rare whisky",
    author: "Caskshare",
  },
};

//  - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export const getMetaText = (
  pageName: PageName,
  metaName: MetaName,
  substitutions?: any,
) => {
  // @ts-ignore
  let text = META_TEXT[pageName][metaName];
  if (substitutions) {
    text = makeSubstitutions(text, substitutions);
  }
  return text;
};

// -------------------------------------------------------------------------------------------------

const COPY_TEXT = {
  ACCOUNT_OVERVIEW_PAGE: {
    "claim-a-caskshare": `Claim a Caskshare`,
    "use-voucher": `Use Voucher`,
  },
  BASKET_PAGE: {},
  CASK_PAGE: {},
  CASKS_PAGE: {
    "reserve-mature-enjoy": `Choose your next whisky from our range of maturing single casks.`,
  },
  PRIVATE_SALE_PAGE: {},
  BALLOT_PAGE: {},
  CHECKOUT_PAGE: {},
  CHECKOUT_SUCCESS_PAGE: {
    "what-happens-next": `Your reservation will appear in your account section and we’ll keep you posted on all updates including when your whisky is ready to be bottled.`,
  },
  GUEST_CHECKOUT_SUCCESS_PAGE: {
    "what-happens-next": `Please create an account to view your reservation in your collection section and we’ll keep you posted on all updates including when your whisky is ready to be bottled.`,
  },
  DISCOVER_PAGE: {
    "not-a-club-a-movement-text": `Caskshare is making single malt scotch whisky, that is still maturing in its cask, available to all.  If you are looking to experience the anticipation of the whisky distilling and ageing process then please sign up and we’ll keep you updated about casks as they become available.`,
    "born-from-a-love-heading": `Born from a love of whisky and a desire to change the game`,
    "born-from-a-love-para-1": `In the end it was quite simple, we wanted to buy some cask whisky from lesser known master distillers.  We wanted that feeling of investing in something that will mature, something that we can enjoy - but we didn’t want whole cask, we wanted a selection, a variety, a portfolio of shares.`,
    "born-from-a-love-para-2": `And we knew others that wanted the same. Either for themselves or for special occasions, births, notable  birthdays, anniversaries. One of the best things about Caskshare, we think, is that you can support the lesser known masters and share in their craft. When the time is right, you can enjoy your share quietly alone or, share your investment with friends, as a gift, or with a loved one.`,
    "select-mature-enjoy-select-and-reserve": `We’ve tried to make it easy.  Explore by taste, distillery or simply browser around. Select your cask and your share.  Once you reserve, well issue your certificate immediately.`,
    "select-mature-enjoy-mature": `Once you’ve reserved your Caskshare… well we wait.  The distillery will be in touch and keep you updated via messages and email.  The bottling may be sent direct to your home or you may, on some ocassions be invited to special tasting - that’s up to the distillery and not always predictable.`,
    "select-mature-enjoy-enjoy": `Alone, contemplating the flavours and notes or with friends at a gathering. Or perhaps you’ve gifted a share to someone special and you’ll be lucky enough to sample a taste. Nothing grows enjoyment levels more than anticipation.`,
    "an-investment-with-character": `Your share in your cask of choice steadiliy matures, taking on all the rich flavours and aromas from it’s environment, developing it’s own special and unique  character.`,
    "partnering-with-the-finest-distilleries": `It is a wonder at how many distilleries there are producing fine single malt cask whisky. Over 130 in Scotland alone.  We build long lasting relationships with the best to bring a share of their casks to those that are keen to experience owning a share in a cask.`,
  },
  DISTILLERIES_PAGE: {
    "the-worlds-finest-distilleries": `Each distillery has its own story, its own heritage and its own tastes. What is for sure is that each distillery cares passionately about their ingredients, their process and the outcomes. Each cask is filled with those passions and care.`,
    "a-common-language-para-1": `Whisky has a language, a lexicon, an allure all of its own.  This language each master distiller employs is the same but the interpretation of the dialect is very different.  The weights and measures, the balance and flavours - from the water and the grain to the wood of casks and the ageing - the end results very much have their own voice.`,
    "a-common-language-para-2": `Some are loud and fierce, others more gentle and soothing, we hope that you’ll find a wealth of variety and selection to meet your personal taste and palette. Our distilleries have found their voice - can they match your preferred tone?`,
    "join-the-movement": `As more distilleries and the results of master distillers craft join Caskshare, more shares become available to enjoy. Caskshare has created more than a club, by democratising the access to cask whisky, we have awakened a movement of whisky lovers.`,
  },
  DISTILLERY_PAGE: {},
  HOME_PAGE: {
    "cookie-consent-explantory-text": `We use cookies on this site to make it work`,
    "cookie-consent-explantory-link": `We use cookies on this site to make it work. If you continue, we'll assume you agree to receive all cookies from the website. `,
    "currency-modal-explantory-text": `Let us know which currency you are using and we’ll adjust everything on the site for you.`,
    introduction: `Caskshare brings whisky lovers the uncompromised experience of your own maturing cask whisky, by the bottle. One bottle, one Caskshare and the world's finest distilleries at your fingertips.`,
    "explainer-unique": `Each single cask whisky offers a unique taste. Caskshare gives you a uniquely rewarding whisky experience.  Browse and search through our distilleries and casks for whisky while it is still in the cask - reserve your share - the shares that matches your personal taste in whisky.`,
    "explainer-timeless": `Waiting… it can be painful, but it can also be very rewarding.  Anticipating the release and bottling of a unique cask of whisky is just part of the journey of discovery.  Setting aside and taking time to enjoy the results of Master Distiller’s time, effort and craft, either with friends or in appreciating a quiet moment - the rich smell and flavours will be memorable and worth the wait.`,
    "explainer-accessible": `Democratising cask whisky - making single cask malt whisky available for all to buy while it is still in the cask! That was the initial dream of our passionate team. We love whisky but could not outlay the amount required for a full cask - but we’d love a good taste, a share and that’s something we can all enjoy.`,
    "this-weeks-choice": `Every week, we highlight one of our particular favourite casks - something that is just a little bit special. Sign up and we’ll send you updates - don’t worry we won’t share your information with anyone else.`,
    "how-it-works-select-and-reserve": `Take your time, have a good browse or dive straight in and choose the tastes and flavours you know you prefer. Find a cask and simply reserve your share - no matter how big or small.  You’ll find all casks have their details of ingredients, finish and timings.  We’ll provide a certificate of every share purchased for you to retain.`,
    "how-it-works-mature": `The waiting… the patience… it is worth it.  Your selected whisky will mature in its cask, gaining flavours and aging until it is ready to be bottled for you. Have you selected for a special event, have you chosen for a gift? Rest assured that once purchased each distiller or seller will arrange for your whisky to be securely delivered into your arms once matured and bottled.`,
    "how-it-works-savour-and-enjoy": `Delivered to you or, at times, joining a special bottling event, your shares in the casks will be securely in your hands to appreciate how you choose. With friends and family, at a special occasion - birthdays, wedding anniversaries, graduations you can buy shares in whisky casks to mature timed with your special occasions. Or simply savour those precious moments and quiet time, contemplating the flavours delivered.`,
    "the-worlds-finest-distilleries": `We build relationships with some of the best distilleries to give you access to the finest Single Cask Scotch Whisky. Our relationships stretch far and wide, each master distiller with their own ingredients, their own way of doing things.`,
  },
  LOGIN_PAGE: {},
  REGISTER_PAGE: {
    "affiliate-question": `Have you been introduced to us by a club?`,
  },
  CONTACT_PAGE: {},
  PRODUCT_DETAILS_PAGE: {},
  SHOP_PAGE: {
    "shop-craft-whisky": `Shop Craft Whisky`,
    "paragraph-craft-whisky": `Enjoy the anticipation as your share in a cask matures… then enjoy the results of your patience`,
  },
  GIFTING_PAGE: {
    "gift-a-share-and": `Gift a share and`,
    "share-the-love": `share the love`,
    "gifting-a-share-is-easy": `Gifting a share in Caskshare is easy. It's a great way to start someone off on their journey of investing in and collecting single cask whisky.`,
    "perfect-for-those-special-occasions": `Perfect for those special occasions`,
    "fathers-and-mothers-days": `Father's and Mother's Days`,
    "births-and-milestone-birthdays": `Births and milestone birthdays`,
    anniversaries: `Anniversaries`,
    "life-events": `Life events`,
    weddings: `Weddings`,
    "buy-a-share-in-a-cask": `Buy a share in a cask that matures when someone you care for - perhaps a god son or daughter was born in the same year as one of our casks - and matures on their 18th or 21st.`,
    "how-does-it-work": `How does it work?`,
    "the-uncompromised-experience": `The uncompromised experience of single cask whisky, by the bottle`,
    "reserve-your-chosen-caskshare": `Reserve your chosen Caskshare`,
    "select-gift-reservation-in-your-account": `Select 'Gift Reservation' in your account`,
    "a-unique-code-is-sent-to-the-lucky-recipient": `A unique code is sent to the lucky recipient`,
    "recipient-creates-an-account-and-claims-their-gift": `Recipient creates an account and claims their gift`,
    "need-help": `Need help?`,
    "get-in-touch": `Get in touch...`,
    email: `contact@caskshare.com`,
    "gift-the-unique-experience": `Gift the unique experience of single cask whisky.`,
    "how-it-works": `How it works`,
    hero: `Gifting a share in Caskshare is easy. It's a great way to start someone off on their journey of investing in and collecting single cask whisky. Buy a share in a cask that matures when someone you care for - perhaps a god son or daughter is born - and matures on their 18th or 21st.`,
    "gift-shares": `With our gift reservation feature you can purchase a reservation on behalf of the lucky recipient, then use our simple and straightforward way of transfering that reservation into your gift recipient's own account. It doesn't matter if the gift recipient doesn't have an account with us, we will take care of the rest.`,
    reserve: `We’ve tried to make it easy. Explore by taste, distillery or simply browser around. Select a cask that fits the moment. Make a reservation (or two!).`,
    select: `Head over to your "My Account" area. Next to each of your reservations you will see a link titled Gift Reservation. Each reservation can be multiple bottles that are gifted in one go. Tell us who the gift is from and write a personalised gift message.`,
    code: `We will email the lucky recipient all of these details and how to claim your gift. Our email will include who the gift is from, your personalised message and a unique code to initiate the gift transfer.`,
    claim: `The gift recipient will be instructed to create an account with us (if they don't already have one). We will then guide them through entering their unique gift code to finalise the transfer of your gift into their account. That's it!`,
    "collective-experience": `Buy a one-year Caskshare Collective Experience of two sample tasting pack selection of some of the products available on Caskshare (each pack is either 5 or 6 x 5cl’s depending on rarity of the spirits selected)`,
    "caskshare-vouchers": `Let your gift recipient make their own choice. With our 'BarrelBank' vouchers we will load your gift recipient's account with the value of your gift voucher. Not only that, we will add 10% reward to be spent with us. It doesn't matter if the gift recipient doesn't have an account with us, we will take care of guiding them through this.`,
  },
  CHECKOUT_TOTALS_COMPONENT: {
    shipping_note:
      "The reserve price you pay covers everything except shipping. Shipping will be charged once your whisky has been bottled.",
    shipping_note_exclusions:
      "Currently our partners cannot deliver into Canada or Finland. If buying from these countries, you will need arrange for your Caskshares to either be shipped to a UK address, or collected from the seller or our Caskshare offices in Edinburgh once bottled.",
    shipping_more_detail: "Please see our FAQs for more information",
  },
  CLAIM_PAGE: {
    "redeem-code": `Redeem Your Voucher Code`,
    "enter-code": `Enter the code you received by email to claim your Caskshare`,
    "enter-gift-code": `Please enter the Voucher Code below`,
    "claim": `CLAIM`,
  },
  CLAIM_COMPONENT: {
    "gifted-you-a-share": `Gifted you a share of single cask whisky`,
    "this-share-has-been-added-to-your-collection": `How Great! This share has been added to your collection`,
  },
  ABOUT_PAGE: {
    // "about-statement-1": `Caskshare has been created to reinvent the way that consumers engage with distilleries and the unique casks, lying dormant waiting for their moment of glory. We want to democratise the ownership of single cask spirits. Everyone that wants to, can get involved, one share, one bottle.`,
    "about-statement-1": `We're all about working with the finest, new wave distilleries out there. Our journey so far has been about building great partnerships to bring you the best whisky the world has to offer. Here's a look at what we've achieved so far.`,
    "about-statement-2": `Being able to build a portfolio of casks with a range of maturation dates means our customers have access to an ever evolving whisky collection - from single cask to glass.`,
    // "about-statement-2": `That’s democracy, getting what was the reserve of the few out to the many. We want spirit lovers to get direct insight into the world of cask ageing. Spirit lovers can build their own cask portfolio and be a real part of their favourite distilleries' output.`,
    // "quote-from-david": `Our ambition is to make maturing single cask whisky available to everyone. Building a collection of single casks is my dream, and using our unique technology, we are bringing bring that dream to like-minded spirit lovers.`,
    "quote-from-david": `Our vision is to make the world of maturing single cask whisky accessible to all. Crafting a collection of these exquisite whiskies is not just a goal but a personal dream. We are turning this dream into a tangible experience for fellow connoisseurs who share a passion for the art of spirit making.`,
    "co-founder": `David Nicol, Co-Founder`,
    "in-the-press": `In the Press`,
    "our-journey": `Our Journey`,
    journey: `We've been busy for years, building relationships and helping craft spirit producers get their unique products into the hands of their fans. We are proud of our relationships, it's them that deserve the glory. We are dedicated to helping spirit masters, using our technology wherever we can. Here's our journey so far, and we've only just got started.`,
    "the-uncompromised-experience": `The Uncompromised Experience of single cask whisky, by the bottle`,
    "caskshare-para-1": `Caskshare is making single malt scotch whisky, that is still maturing in its cask, available to all.`,
    "caskshare-para-2": `If you are looking to experience the anticipation of the whisky distilling and ageing process then please sign up and we’ll keep you updated about casks as they become available.`,
  },
  PRESS_PAGE: {
    introduction: `Below is a selection of the kinds words that have been said about us. We are passionate about our mission, and we hope this comes across in our chats with the press.`,
  },
  THE_COLLECTIVE: {
    "exclusive-benefits": `Let us bring the distillery visitors centre to you. Join the Caskshare Collective to receive exclusive access to unique whiskies and monitor their progress as they mature, direct to your door.`,
    "barrel-bank": `For every £100 spent from your Barrelbank you get £5 reward towards your purchase. This means you can save money each month, spread the cost of your Caskshare purchase and earn discounts along the way.`,
    "save-every-month": `Add between £5 - £200 / month to your BarrelBank to use on Caskshares and ready bottled shop.`,
    "earn-discounts": `Earn discounts on your Caskshare purchases and keep track of Caskshare savings and discounts.`,
  },
  MY_COLLECTIVE: {
    "exclusive-benefits": `Let us bring the distillery visitors centre to you. Join the Caskshare Collective to receive exclusive access to unique whiskies and monitor their progress as they mature, direct to your door.`,
    "barrel-bank": `For every £100 spent from your Barrelbank you get £5 reward towards your purchase. This means you can save money each month, spread the cost of your Caskshare purchase and earn discounts along the way.`,
    "save-every-month": `Add between £5 - £200 / month to your BarrelBank to use on Caskshares and ready bottled shop.`,
    "earn-discounts": `Earn discounts on your Caskshare purchases and keep track of Caskshare savings and discounts.`,
  },
  SIGNATURE_SERIES_PAGE: {
    "": ``,
  },
  SELL_YOUR_CASK: {
    "list-your-cask": `LIST YOUR CASK ON CASKSHARE AND YOU CAN SELL SOME BOTTLES, KEEP SOME BOTTLES`,
    "caskshare-is-platform": `Caskshare is an online platform for purchasing unique single cask whisky from distilleries around the world. Caskshare brings whisky lovers a new way to engage with distilleries on a modern and easy-to-use platform, discovering and accessing exciting single cask whiskies to enjoy now or mature on for later. Caskshare brings you the uncompromised experience of single cask whisky, by the bottle. One bottle, one Caskshare and the world’s finest distilleries at your service.`,
    "caskshare-looks-forward": `Caskshare looks forward to partnering with cask owners who desire to democratise their whisky casks, making single cask whiskies more accessible to everyone. If you own a cask and would like to sell some bottles, Caskshare can help.`,
    "step-1": `Send us your details together with details of the cask(s) you are considering working with us on. We will respond to you with details of our onboarding process as detailed in the next steps.`,
    "step-2": `We will work with you on pricing, bottling options, bottling timing and price options. As is normal practice when onboarding new customers, we will also send you a know-your-customer request.`,
    "step-3": `We will send you our Heads of Terms and Seller Terms and Conditions. Our T&C’s detail our obligations to you as well as your obligations. These can be signed digitally.`,
    "step-4": `We will work with you to obtain up-to-date cask regauge and proof of cask storage. We ask that you send us a cask sample and our whisky panel will review this and generate a report. This report will be available to you and assuming all is good with our panel’s report, this will be used as the basis of the cask flavour profile on the Caskshare.com cask listing.`,
    "step-5": `We will work with you to list your cask(s) on Caskshare.com and agree launch timing of your listing(s). Our payment processor is Stripe.com. If you can, we will connect to your Stripe.com account so that we can send your funds in realtime. We will also provide you a near realtime dashboard of all sales so you can keep an eye on your sales.`,
  },
  GIFTING_PAGE_VOUCHER: {
    paragraph: `Let your gift recipient make their own choice. With our 'BarrelBank' vouchers we will load your gift recipient's account with the value of your gift voucher. Not only that, we will add 10% reward to be spent with us. It doesn't matter if the gift recipient doesn't have an account with us, we will take care of guiding them through this.`,
    "bullet-1": `Can be spent on Caskshares and our bottle shop`,
    "bullet-2": `Recipients will receive their voucher via email`,
    "bullet-3": `We will reach out to the recipient on your selected date`,
  },
  GIFTING_PAGE_MEMBERSHIP: {
    paragraph: `Buy a one-year Caskshare Collective to benefit from perks such as free shipping and Member pricing. `,
    "bullet-1": `Member pricing`,
    "bullet-2": `Free shipping on all orders`,
    "bullet-3": `Access to BarrelBank and more`,
    "bullet-4": `If you would like to download a gift letter please click here`,
  },
  GIFTING_PAGE_CASKSHARES: {
    paragraph: `You can purchase a Caskshare as a gift - all you need to do is reserve the Caskshare in your recipients name. Don’t worry if they don’t have an account with us yet, we’ll take care of that when they claim their gift.`,
    "bullet-1": `Select the Caskshare you wish to gift`,
    "bullet-2": `A unique code is sent to the lucky recipient`,
    "bullet-3": `Recipient creates an account and claims their gift`,
  },
};

//  - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
export const getCopyText = (
  pageName: PageName,
  copyName: string,
  substitutions?: any,
) => {
  // @ts-ignore
  let text = COPY_TEXT[pageName][copyName];
  if (substitutions) {
    text = makeSubstitutions(text, substitutions);
  }
  return text;
};

// -------------------------------------------------------------------------------------------------

interface StyleValues {
  text: string;
  icon: string;
}

const SPIRIT_STYLES: { [key: string]: StyleValues } = {
  unpeated: {
    text: "Un-Peated Whisky",
    icon: "",
  },
  peated: {
    text: "Peated Whisky",
    icon: "",
  },
  grain: {
    text: "Grain",
    icon: "",
  },
  bourbon: {
    text: "Bourbon",
    icon: "",
  },
  rye: {
    text: "Rye",
    icon: "",
  },
  wheat: {
    text: "Wheat",
    icon: "",
  },
  four_grain: {
    text: "Four Grain",
    icon: "",
  },
  millet: {
    text: "Millet",
    icon: "",
  },
  corn: {
    text: "Corn",
    icon: "",
  },
  rum: {
    text: "Rum",
    icon: "",
  },
};

//  - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
type SpiritStyle =
  | "peated"
  | "unpeated"
  | "grain"
  | "bourbon"
  | "rye"
  | "wheat"
  | "four_grain"
  | "millet"
  | "corn"
  | "rum";
type SpiritTypeAttr = "text" | "icon";

export const getSpiritStyle = (style: string, attr: SpiritTypeAttr) => {
  if (style in SPIRIT_STYLES) {
    return SPIRIT_STYLES[style as SpiritStyle][attr];
  }
};

// -------------------------------------------------------------------------------------------------

const PRODUCTION_METHODS: { [key: string]: StyleValues } = {
  pot_still: {
    text: "Batch Distillation",
    icon: "",
  },
  column_still: {
    text: "Column Still",
    icon: "",
  },
  pot_column_hybrid: {
    text: "Pot/Column Hybrid",
    icon: "",
  },
  pot_triple_distilled: {
    text: "Triple Distilled (Batch)",
    icon: "",
  },
  hybrid_triple_distilled: {
    text: "Triple Distilled (Hybrid)",
    icon: "",
  },
};

//  - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
type ProductionMethod =
  | "pot_still"
  | "column_still"
  | "pot_column_hybrid"
  | "pot_triple_distilled"
  | "hybrid_triple_distilled";
type ProductionMethodAttr = "text" | "icon";

export const getProductionMethod = (
  method: string,
  attr: ProductionMethodAttr,
) => {
  if (method in PRODUCTION_METHODS) {
    return PRODUCTION_METHODS[method as ProductionMethod][attr];
  }
};
